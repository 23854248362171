import React from 'react';
import './App.css';
import pythonIMG from './files/pythonIMG.png' 
import postgresIMG from './files/postgresqlIMG.png'
import expressIMG from './files/expressIMG.png'
import reduxIMG from './files/reduxIMG.png'
import es6IMG from './files/es6IMG.png'
import html5IMG from './files/html5IMG.png'
import css3IMG from './files/css3IMG.png'
import lessIMG from './files/lessIMG.png'
import nodeIMG from './files/nodeIMG.png'
import reactIMG from './files/reactIMG.jpg'
import knexIMG from './files/knexIMG.png'
import gitIMG from './files/gitIMG.png'
import wowoIMG from './files/wowoIMG.jpg'
import awscpIMG from './files/awscp.png'
import awscp1IMG from './files/awscp2.png'
// import blackballIMG from './files/blackballIMG.png'
import tipseaseIMG from './files/tipsease.png'
import libIMG from './files/libIMG.png'
import givingTuesdayIMG from './files/givingTuesdayIMG.PNG'
import lambdaIMG from './files/lambda.png'
import subtransIMG from './files/sub-trans-srt.png'
import collection2IMG from './files/collection2IMG.jpg'
import storeinvmanIMG from './files/storeinventorymanager.jpg'

function App() {

  const skillsArray = [
    {
      language:"React.JS",
      image: reactIMG,
    }, 
    {
      language:"Node",
      image: nodeIMG,
    }, 
    {
      language:"Express",
      image: expressIMG,
    }, 
    {
      language:"Git & GitHub",
      image: gitIMG,
    },
    {
      language:"PostgreSQL",
      image: postgresIMG,
    }, 
    {
      language:"Leaner Style Sheets - LESS",
      image: lessIMG,
    }, 
    {
      language:"Python3",
      image: pythonIMG,
    }, 
    {
      language:"Redux",
      image: reduxIMG,
    }, 
    {
      language:"Knex.js",
      image: knexIMG,
    },
    {
      language:"Javascript ES6+",
      image: es6IMG,
    }, 
    {
      language:"HTML 5",
      image: html5IMG,
    },
    {
      language:"CSS 3",
      image: css3IMG,
    },
    {
      language:"Docker",
      image:"https://upload.wikimedia.org/wikipedia/en/thumb/f/f4/Docker_logo.svg/1920px-Docker_logo.svg.png",
    },
    {
      language:"Kubernetes",
      image:"https://upload.wikimedia.org/wikipedia/commons/thumb/3/39/Kubernetes_logo_without_workmark.svg/1024px-Kubernetes_logo_without_workmark.svg.png",
    },
    {
      language:"Java",
      image:"https://upload.wikimedia.org/wikipedia/en/thumb/3/30/Java_programming_language_logo.svg/800px-Java_programming_language_logo.svg.png",
    },
    {
      language:"C++",
      image:"https://upload.wikimedia.org/wikipedia/commons/thumb/1/18/ISO_C%2B%2B_Logo.svg/1024px-ISO_C%2B%2B_Logo.svg.png",
    },
    {
      language:"AWS",
      image:"https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/Amazon_Web_Services_Logo.svg/1920px-Amazon_Web_Services_Logo.svg.png",
    },
    {
      language:"Linux",
      image:"https://upload.wikimedia.org/wikipedia/commons/thumb/3/35/Tux.svg/1024px-Tux.svg.png",
    },
  ]
  
  const projectsArray = [
    // {
      //   name:"Blackball.co",
      //   projectIMG:blackballIMG,
      //   sampleURL:"https://blackball.co/",
      //   stack:"React | Python/Flask Node/Express/PostgreSQL | Netlify | Heroku",
      //   description:"• An in-development blockchain solution to tracking law enforcement civil rights abuses.  Verified abuse of power complaints would be added to a blockchain, which may be freely searched by anyone. \n \n• The idea is that a police department looking to hire new officers could search their applicant's name in the database to ensure they don't have histories of abusive behavior.  \n \n• Alternatively, a civil rights attorney might search the arresting officers in a case, and be pointed to past of instances in which the arresting officers may have acted inappropriately. This could potentially provide compelling character evidence against an officer in support of a beleaguered civilian party.  \n \n• A Blackball for the Blue Wall of Silence.",
      //   github:"https://github.com/cahaug/BlackBall"
      // },
      // {
        //   name:"Tipsease.io",
    //   projectIMG:tipseaseIMG,
    //   sampleURL:"https://tipsease.io/",
    //   stack:"HTML5 | LESS / CSS3 | Netlify",
    //   description:"• A sample website for a hypothetical company where tipping workers directly is possible. \n \n•A Lambda School project, where I worked in a team of two to build these pages.",
    //   github:"https://github.com/tipsease-webpt6/marketing_tipsease"
    // },
    // {
    //   name:"Sub-Trans-SRT",
    //   projectIMG:subtransIMG,
    //   sampleURL:"https://www.npmjs.com/package/sub-trans-srt",
    //   stack:"Javascript, Regex",
    //   description:"• I built this NPM Package to help me with learning foreign languages. \n \n• The code allows for command-line and programmatic batch translation of SRT Subtitle Files. \n \n• Each subtitle file is read into a queue, and lines are translated in batches until the batch is complete. \n \n• The translations can be additively combined or simply swapped for the origin text. \n \n• Multiple APIs are supported, including Yandex Translate, and Google Translate",
    //   github:"https://github.com/cahaug/sub-trans-srt"
    // },
    // {
    //   name:"storeinventorymanager",
    //   projectIMG:storeinvmanIMG,
    //   sampleURL:"https://github.com/cahaug/storeinventorymanager",
    //   stack:"Java | Spring Boot",
    //   description:"• An Open Source Complete Retail Inventory Management System. \n \n• Command Line Interface, API coming soon.  \n \n• Item inventories are persistently tracked.  \n \n• Sales detract from inventory and shipments can be added directly to inventory.",
    //   github:"https://github.com/cahaug/storeinventorymanager"
    // },
    {
      name:"Link-In.Bio",
      projectIMG:libIMG,
      sampleURL:"https://link-in.bio/",
      stack:"React/Redux | Node/Express/PostgreSQL | Netlify | DigitalOcean",
      description:"• I solely designed and coded this project to address the less than optimal strategies employed by companies, social media marketers, and other internet users in linking, tracking & monetizing content.   \n \n• Link-In.bio is a website where someone can register to create their own publicly hosted pages of lists of hyperlinks with and without photos for use with social media accounts.  \n \n• Pageviews of a list are recorded, and clickthroughs data from a link is scraped by the server, and viewer details like location are available to view.  \n \n• User registration is open, and additional options including user-specific fonts, and color-customization options are available.  \n \n• Registration is completely free, and user lists are infinitely customizable. \n \n• A URL Shortener is available, completely free of charge, which utilizes the same Link-in.Bio Analytics API as their lists.",
      github:"https://github.com/cahaug/link-in.bio"
    },
    {
      name:"Collection-2-NFT.com",
      projectIMG:collection2IMG,
      sampleURL:"https://collection-2-nft.com",
      stack:"Node | Express | React | Docker | Kubernetes",
      description:"• A custom, in-house solution for creating NFT Collections from real-life art. \n \n• Function allows for upload, edit, design, and creation of NFTs from files and images.  \n \n• Static images and animated GIFs are supported.  \n \n• Solana function working, Ethereum function planned.",
      github:"https://github.com/cahaug/nft-designer"
    },
    {
      name:"Giving-Tuesday.net",
      projectIMG:givingTuesdayIMG,
      sampleURL:"https://giving-tuesday.net",
      stack:"HTML5 | LESS / CSS3 | Netlify",
      description:"• A website designed to inspire people to donate to reputable and efficient charitable organizations that are near and dear to my heart. \n \n• I built this for Giving-Tuesday 2018 and refreshed it for Giving-Tuesday 2021.  \n \n• I built both a React-App version of this website and a HTML/CSS version.  \n \n• React version available at https://giving-tuesday.net/ ",
      github:"https://github.com/cahaug/giving-Tuesday2"
    },
    {
      name:"Wo-Wo: Wax On Wax Off",
      projectIMG:wowoIMG,
      sampleURL:"https://wowo-app2.netlify.app/",
      stack:"React/Redux | Node/Express/PostgreSQL | Netlify | Heroku",
      description:"• A Lambda School Project. Given the directive to build the Uber of Car Washing, a team of five implemented an action plan, developed release canvases, and built Wo-Wo from nothing.  \n \n• I wrote the entire backend on this project so I am proud of that but I am most proud of my instant quote generator, which has over 1000 vehicles to choose from, all with individually calculated prices. \n \n• Check it out on the homepage, under the Instant Quote Button.",
      github:"https://github.com/cahaug/wowo-be"
    },
  ]

  return (
    <div className="container">

      <header className="nav">
        <h1 className="name">C. Alexander Haug</h1>
        <a className="headerLink" href="#skill">Skills</a>
        <a className="headerLink" href="#package">Packages</a>
        <a className="headerLink" href="#project">Projects</a>
        <a className="headerLink" href="#contact">Contact</a>
        <a className="headerLink" href="#certs">Certifications</a>
      </header>

      <section className="intro">
        <div className="introContainer">
          <h3>Hello There, I'm Alex.</h3>
          <br />
          <h3>I'm a Freelance Full-Stack Web Developer & Programmer.</h3>
          {/* <h3>🌵🤠Currently in: Scottsdale, Arizona 🤠🌵</h3> */}
        </div>
        <a name="skill"></a>
      </section>

      <section className="skillContainer">
        <h2>Technologies Used in my Projects:</h2>
        <br /><hr />
        <div className="skills">
          {skillsArray.map((skill) => {
            return (
              <div className="individualSkill">
                <img className="skillImage" src={skill.image} title={skill.language} alt={skill.language} />
                {/* <h4>{skill.language}</h4> */}
              </div>
            )
          })}
          <a name="package"></a>
        </div>
      </section>

      <hr /><br />
      
      <section className="projectsContainer">
        <h3>Released NPM Packages:</h3>
        <div className="projects">
          <div className="individualProject">
            <a className="projectURL" href="https://www.npmjs.com/package/sub-trans-srt">sub-trans-srt</a>
            <hr/>
            <h6 className="projectDescription">• A Batch Subtitle Translator for SRT Files built with Node.</h6>
            <h6 className="projectDescription">• Convert subtitles from one language to another with a single command.</h6>
          </div>
          <div className="individualProject">
            <a className="projectURL" href="https://www.npmjs.com/package/deep-file-search">deep-file-search</a>
            <hr/>
            <h6 className="projectDescription">• Inventories all files in a given parent directory for any given keyword.</h6>
            <h6 className="projectDescription">• Searches inside every file in a directory with a single command.</h6>
            <h6 className="projectDescription">• Displays context of a search term and its location.</h6>
            <h6 className="projectDescription">• It can export results to file or simply display them</h6>
          </div>
          <div className="individualProject">
            <a className="projectURL" href="https://www.npmjs.com/package/quick-teleprompter">quick-teleprompter</a>
            <hr/>
            <h6 className="projectDescription">• Open an in-browser, no-dependency teleprompter-- with just one simple command.</h6>
            <h6 className="projectDescription">• Manual advance and time-based advance functions.</h6>
            <h6 className="projectDescription">• Light mode and dark mode available.</h6>
          </div>
          <div className="individualProject">
            <a className="projectURL" href="https://www.npmjs.com/package/linkedin-crawler">linkedin-crawler</a>
            <hr/>
            <h6 className="projectDescription">• Automated crawling and messaging for LinkedIn.</h6>
            <h6 className="projectDescription">• Search for LinkedIn profiles by keyword, and automatically send connection requests to the search results.</h6>
            <h6 className="projectDescription">• Customize requests with automated, customized messages, tailored to the recipient.</h6>
            <h6 className="projectDescription">• Light mode and dark mode available.</h6>
          </div>
          <div className="individualProject">
            <a className="projectURL" href="https://www.npmjs.com/package/author-total-downloads">author-total-downloads</a>
            <hr/>
            <h6 className="projectDescription">• Prints any NPM author's total package downloads to the command line.</h6>
            <h6 className="projectDescription">• No dependencies, works with any user's NPM account.</h6>
            <h6 className="projectDescription">• Displays downloads by package as well as sum.</h6>
            <h6 className="projectDescription">• Simple, single command operation.</h6>
          </div>
          <div className="individualProject">
            <a className="projectURL" href="https://www.npmjs.com/~calex">Packages In-Progress</a>
            <hr/>
            <h6 className="projectDescription">• <strong>image-generator:</strong><br/>procedural visual art generation demonstration<br/></h6>
            <h6 className="projectDescription">• <strong>stacker:</strong><br/>create loading diagrams for most efficient loading of any number of arbitrarily sized objects<br/></h6>
            <h6 className="projectDescription">• <strong>store-inventory-manager:</strong><br/>all-in-one retail store inventory management and sales system<br/></h6>
            <h6 className="projectDescription">• <strong>movie-website-timeshifter:</strong><br/>timeshift video streams on drm-protected streaming video website (+ Chrome Browser Extension)<br/></h6>
            <h6 className="projectDescription">• <strong>of-ripper:</strong><br/>download image and video files from drm-protected website (+ Chrome Browser Extension)<br/></h6>
            <h6 className="projectDescription">• <strong>video-encrypt:</strong><br/>PGP encrypt video streams for secure, private, video chat<br/></h6>
            <h6 className="projectDescription">• <strong>mp3-bpm-analysis:</strong><br/>determine any MP3 file's beats per minute for song mixing and matching<br/></h6>
            <h6 className="projectDescription">• <strong>info-out:</strong><br/>encrypted file and streaming video exfiltration<br/></h6>
            <h6 className="projectDescription">• <strong>working-react-native-picker:</strong><br/>the be-all and end-all react native package for selects, dropdowns, and more<br/></h6>
          </div>
        </div>
        <a name="project"></a>
      </section>
      
      <hr /><br />
      
      <section className="projectsContainer">
        <h3>Completed and Ongoing Projects:</h3>
        <div className="projects">
          {projectsArray.map((project) => {
            return (
              <div className="individualProject">
                <a href={project.sampleURL} alt={project.name}>
                  <img className="projectImage" src={project.projectIMG} title={project.name} alt={project.name} />
                </a>
                <hr />
                <h4 className="projectName">{project.name}</h4>
                <hr />
                <h5 className="projectStack">{project.stack}</h5>
                <hr />
                <h5 className="projectURL"><a href={project.sampleURL} alt={project.name}>{project.sampleURL}</a></h5>
                <hr />
                {/* <a href={project.github} alt={project.github}><button>View on GitLab</button></a> */}
                {/* <hr /> */}
                <h6 className="projectDescription">{project.description}</h6>
              </div>
            )
          })}
        </div>
      </section>
     
      <section className="contactContainer">
          <a name="contact"></a>
        <div className="contacts">
          <h3>Want to Talk Web Development?</h3>
          <h3>Contact Me!</h3>
          <br /><hr /> 
          <form action="mailto:calehaug@live.com" method="GET">
            <label>Subject: <br />
              <input name="subject" type="text" />
            </label>
            <label>Message: <br />
              <textarea name="body"></textarea>
            </label>
            <label>Submit: <br />
              <input className="button" type="submit" value="Send" />
            </label>
            <label>Clear Form: <br /> 
              <button className="button" type="reset">Reset</button>
            </label>
          </form>
          <a name="certs"></a>
          <hr /><br />
          <div><a href="https://www.youracclaim.com/badges/e5aae76f-6c66-43b9-a275-a3bd1ea324ac/public_url"><img className="lambdaIMG" src={lambdaIMG} alt="View my Badge on Acclaim by Credly" title="View my Badge on Acclaim by Credly" /></a></div>
          <div><a href="https://www.credly.com/badges/0fd0a14e-d632-49af-8f50-b6d4e258df09/public_url"><img className="lambdaIMG" src={awscp1IMG} alt="View my Badge on Acclaim by Credly" title="View my Badge on Acclaim by Credly" /></a></div>          
          <div><a href="https://www.credly.com/badges/97a44e57-9ff8-43e2-9e8e-2dc5bcc5df33/public_url"><img className="lambdaIMG" src={awscpIMG} alt="View my Badge on Acclaim by Credly" title="View my Badge on Acclaim by Credly" /></a></div>          
          <h3>External Links:</h3>
          <div className="socialLinks">
            {/* <a href="https://www.linkedin.com/in/calehaug/" alt="My LinkedIn"><h4>LinkedIn</h4></a> */}
            {/* <a href="https://twitter.com/Cale_Haug" alt="My Twitter"><h4>Twitter</h4></a> */}
            {/* <a href="https://еф.ею/ж" alt="My Link In Bio"><h4>Link-In.Bio</h4></a> */}
            <a href="https://еф.ею/" alt="Link In Bio"><h4>Link-In.Bio</h4></a>
            <a href="https://gitlab.com/calexh" alt="My GitHub"><h4>GitLab</h4></a>
            <a href="https://github.com/cahaug" alt="My GitHub"><h4>GitHub (Deprecated)</h4></a>
          </div>
          <hr /><br />
        <h3>©{new Date().getFullYear()} - Built with Tender Love and Care by C. Alex Haug</h3>
        </div>
      </section>
    </div>
  );
}

export default App;
